import {HomeState} from "./type/home";
import {HomeResultInterface} from "../action/type/home";

export const initHome = {
    data: [] as object[],
    artist: [] as object[],
    lienkhuc: [] as object[],
    banner: [] as object[],
    rank: [] as object[],
    search: {
        media: [] as object[],
        singer: [] as object[],
        history: [] as object[],
        media_count: 0,
        singer_count: 0,
        media_link: '',
        singer_link: ''
    },
}

function home (state: HomeState = initHome, action: HomeResultInterface) : HomeState {
    switch (action.type) {
        case "INFO_HOME":
            return {...state, data: action.data }
        case "INFO_ARTIST":
            return {...state, artist: action.artist }
        case "INFO_LIENKHUC":
            return {...state, lienkhuc: action.lienkhuc}
        case "INFO_BANNER":
            return {...state, banner: action.banner }
        case "SEARCH_DATA":
            return {...state, search: action.search }
        case "INFO_RANK":
            return {...state, rank: action.rank }
        case "LOADMORE_SEARCH":
            return {
                ...state,
                search: {
                    media: state.search.media.concat(action.search.media),
                    singer: state.search.singer.concat(action.search.singer),
                    media_count: state.search.media_count,
                    singer_count: state.search.singer_count,
                    media_link: action.search.media_link,
                    singer_link: action.search.singer_link,
                    history: state.search.history
                }
            }
        default:
            return state;
    }
}

export default home
