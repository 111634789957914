import { combineReducers } from 'redux'
import category from './category'
import home from './home'
import song from './song'
import auth from "./auth";
import artist from './artist'
import packageRs from './package'

const root = combineReducers({
    category: category,
    home: home,
    song: song,
    auth: auth,
    artist: artist,
    package: packageRs
});

export default root