import * as React from "react"
import {CategoryState} from "../reducer/type/category";
import StateInterface from "../reducer/type";
import {bindActionCreators, Dispatch} from "redux";
import * as CategoryAction from "../action/category";
import * as HomeAction from "../action/home";
import {connect} from "react-redux";
import {ArtistItemInterface, HomeState} from "../reducer/type/home";
import Menu from "../component/Menu";
import Header from "../component/Header";
import LoadingScreen from "../component/LoadingScreen";
import CategoryList from "../component/CategoryList";
import {Link} from "react-router-dom";
import * as AuthAction from "../action/auth";
import * as ls from "local-storage";
import * as $ from "jquery";
import LoadMore from "../component/LoadMore";

interface StatePropsInterface {
    category: CategoryState,
    home: HomeState
}

interface ParamPropsInterface {
    match: {
        params: {
            query: string
        }
    },
    location: any,
    history: any
}

interface DispatchPropsInterface {
    actions: {
        getCategoryAction: any,
        searchDataAction: any,
        getInfoAction: any,
        loadmoreSearchAction: any
    }
}

const mapStateToProps = (state: StateInterface) => ({
    category: state.category,
    home: state.home
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({
        getCategoryAction: CategoryAction.getCategoryAction,
        searchDataAction: HomeAction.searchDataAction,
        getInfoAction: AuthAction.getInfoAction,
        loadmoreSearchAction: HomeAction.loadMoreSearchAction
    }, dispatch)
});

type PropsInterface = StatePropsInterface & DispatchPropsInterface & ParamPropsInterface

interface CurrentStateInterface {
    loading: boolean,
    loadmore: boolean,
    type: string
}

class Search extends React.Component <PropsInterface, CurrentStateInterface> {

    constructor (props: PropsInterface) {
        super(props);
        this.props.actions.getCategoryAction();
        if (typeof ls.get<string>('token') == 'string') {
            this.props.actions.getInfoAction();
        }
        this.state = {
            loading: true,
            loadmore: false,
            type: 'media'
        }

        this.trackScrolling = this.trackScrolling.bind(this)
    }

    trackScrolling () {
        const wrappedElement = document.getElementById('container');
        if ( (wrappedElement.getBoundingClientRect().bottom - 74) <= window.innerHeight ) {
            if (this.state.type == 'media') {
                if (this.props.home.search.media_link != '' && this.state.loadmore == false) {
                    this.setState({
                        loadmore: true
                    });
                    this.props.actions.loadmoreSearchAction(this.props.home.search.media_link, this.props.match.params.query);
                }
            } else if (this.state.type == 'singer') {
                if (this.props.home.search.singer_link != '' && this.state.loadmore == false) {
                    this.setState({
                        loadmore: true
                    });
                    this.props.actions.loadmoreSearchAction(this.props.home.search.singer_link, this.props.match.params.query);
                }
            }
        }
    }

    componentDidMount() {
        this.props.actions.searchDataAction(this.props.match.params.query);
        // Scroll
        document.addEventListener('scroll', this.trackScrolling, false);
        document.addEventListener('resize', this.trackScrolling, false);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling, false);
        document.removeEventListener('resize', this.trackScrolling, false);
    }

    componentDidUpdate(prevProps) {
        if (this.props.home.search !== prevProps.home.search) {
            this.setState({
                loading: false,
                loadmore: false
            })
        }
    }

    handleChangeType(type: string) {
        this.setState({
            type: type
        })
    }

    render() {
        let loadingScreen;
        if (this.state.loading) {
            loadingScreen = (<LoadingScreen/>)
        }

        let artistList = this.props.home.search.singer.map((item: ArtistItemInterface, key: number) => {
            return (
                <div className="col-6" key={key}>
                    <div className="pr-0 pl-0">
                        <Link to={'/ca-si/'+ item.id + '-' + item.slug} className="content-item">
                            <img className="content-item-singer" src={ item.thumbnail_url }/>
                            <div className="content-item-head text-center">
                                {item.name}
                            </div>
                        </Link>
                    </div>
                </div>
            );
        });

        let noneVideo;
        if (this.props.home.search.media.length == 0) {
            noneVideo = (<div className="no-search mt-3">
                Không tìm thấy kết quả nào
            </div>);
        }

        let noneSinger;
        if (this.props.home.search.singer.length == 0) {
            noneSinger = (<div className="no-search mt-3">
                Không tìm thấy kết quả nào
            </div>);
        }

        let searchHistory;
        if (this.props.home.search.history.length > 0) {
            let historyItem = this.props.home.search.history.map((item: any) => {
                return (
                    <Link to={'/tim-kiem/' + item.query} className="btn btn-secondary btn-sm search-history mr-2">{item.query}</Link>
                )
            })

            searchHistory = (
                <div className="row">
                    <div className="col-12">
                        <h6 className="mt-2">Lịch sử tìm kiếm</h6>
                    </div>
                    <div className="col-12">
                        {historyItem}
                    </div>
                </div>
            )
        }

        let loadmoreView;
        if (this.state.loadmore) {
            loadmoreView = (<LoadMore/>)
        }

        return (
            <div id="container" className="container-fluid overflow-hidden">
                <div className="menu-overlay"/>
                <Menu/>
                <Header/>
                {loadingScreen}

                <h3 className="mt-3">Từ khóa: {this.props.match.params.query}</h3>

                {searchHistory}

                <div className="row">
                    <div className="col-12">
                        <h6 className="mt-3"></h6>
                    </div>
                </div>

                <ul className="nav nav-pills" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a onClick={this.handleChangeType.bind(this, 'media')} className="nav-link active" id="video-tab" data-toggle="tab" href="#video" role="tab" aria-controls="home" aria-selected="true">
                            Bài hát
                            ({this.props.home.search.media_count})
                        </a>
                    </li>
                    <li className="nav-item">
                        <a onClick={this.handleChangeType.bind(this, 'singer')} className="nav-link" id="singer-tab" data-toggle="tab" href="#singer" role="tab" aria-controls="profile" aria-selected="false">
                            Ca sĩ
                            ({this.props.home.search.singer_count})
                        </a>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="video" role="tabpanel" aria-labelledby="home-tab">
                        <div className="row">
                            <CategoryList video={this.props.home.search.media}/>
                        </div>
                        {noneVideo}
                        {loadmoreView}
                    </div>
                    <div className="tab-pane fade" id="singer" role="tabpanel" aria-labelledby="profile-tab">
                        <div className="row">
                            {artistList}
                        </div>
                        {noneSinger}
                        {loadmoreView}
                    </div>
                </div>

            </div>
        );
    }

}

export default connect<StatePropsInterface, DispatchPropsInterface>(
    mapStateToProps,
    mapDispatchToProps
)(Search);