import { PackageState } from "./type/package";
import {
    PackageResultInterface
} from "../action/type/package";

export const initPackage = {
    data: [] as object[],
    user: [] as object[]
}

function packageRs (state: PackageState = initPackage, action: PackageResultInterface): PackageState {

    switch (action.type) {

        case "GET_PACKAGE":
            return {
                ...state,
                data: action.package
            }

        case "USER_PACKAGE":
            return {
                ...state,
                user: action.user
            }

        default:
            return state

    }

}

export default packageRs

