import * as React from "react"
import Menu from "../component/Menu";
import Header from "../component/Header";

class Tutorial extends React.Component {

    render() {
        return (
            <div id="container" className="container-fluid overflow-hidden package-page">
                <div className="menu-overlay"/>
                <Menu/>
                <Header/>
                <div className="row">
                    <div className="col-12">
                        <div className="text-center mt-3" style={{fontSize: '18px', fontWeight: 500}}>HDSD DỊCH VỤ IBOLERO</div>
                        <div className="mt-2" style={{fontWeight: 500, textAlign: 'justify'}}>Bolero là dòng nhạc trữ tình mang âm hưởng giai điệu quê hương. Dòng nhạc này không hề khó hát, không cần phải học qua trường lớp vẫn có thể hát được với những giai điệu đi vào lòng người, rất được mến mộ tại Việt Nam nói chung và miền Nam nói riêng. Dịch vụ iBolero cho phép thuê bao MobiFone xem video chất lượng cao và tải nội dung theo nhu cầu trên thiết bị di động không giới hạn data.</div>
                        <div className="mt-3">Khách hàng truy cập vào Wapsite dịch vụ tại địa chỉ http://ibolero.vn/, màn hình điện thoại sẽ hiện ra trang chủ Wap Ibolero:</div>
                        <div className="mt-3" style={{fontWeight: 'bold'}}>1. Trang chủ:</div>
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-1.png") } />
                        <div className="mt-3" style={{fontWeight: 'bold'}}>2. Mục Menu:</div>
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-2.png") } />
                        <div className="mt-3" style={{fontWeight: 'bold'}}>3. Đăng nhập và đăng ký tài khoản IBolero:</div>
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-3.png") } />
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-4.png") } />
                        <div className="mt-3" style={{fontWeight: 'bold'}}>4. Bảng xếp hạng:</div>
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-5.png") } />
                        <div className="mt-3" style={{fontWeight: 'bold'}}>5. Chuyên mục nhạc:</div>
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-6.png") } />
                        <div className="mt-3" style={{fontWeight: 'bold'}}>6. Đăng ký sử dụng dịch vụ:</div>
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-11.png") } />
                        <div className="mt-3">Click đăng ký với thuê bao đang sử dụng 3G/4G MobiFone: Khách hàng sẽ phải xác nhận qua sms để đăng ký.</div>
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-7.png") } />
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-8.png") } />
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-9.png") } />
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-10.png") } />
                        <div className="mt-3">Click đăng ký với thuê bao đang sử dụng WiFi: Khách hàng sẽ phải xác nhận qua sms 2 lần để đăng ký.</div>
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-7.png") } />
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-12.png") } />
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-13.png") } />
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-10.png") } />
                        <div className="mt-3" style={{fontWeight: 'bold'}}>7. Ca sĩ:</div>
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-14.png") } />
                        <div className="mt-3" style={{fontWeight: 'bold'}}>8. Nghe nhạc và những tính năng chuyên sâu:</div>
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-15.png") } />
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-16.png") } />
                        <div className="mt-3" style={{fontWeight: 'bold'}}>9. Tìm kiếm thông minh:</div>
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-17.png") } />
                        <div className="mt-3" style={{fontWeight: 'bold'}}>10. Lấy mật khẩu:</div>
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-18.png") } />
                        <div className="mt-3" style={{fontWeight: 'bold'}}>11. Tra cứu dịch vụ:</div>
                        <img className="mt-3" style={{width: '100%'}} src={ require("../asset/images/hdsd-19.png") } />
                    </div>
                </div>
            </div>
        );
    }

}

export default Tutorial