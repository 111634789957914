import { SongState } from "./type/song";
import {
    SongResultInterface
} from "../action/type/song";

export const initSong = {
    data: {
        id: '',
        name: '',
        slug: '',
        thumbnail_url: '',
        artist: '',
        link_video: '',
        favorite: false,
        somg_time: 0,
        link_stream: [] as object[],
        link_download: [] as object[],
        view_count: 0,
        relate_song: [] as object[],
    }
}

function song(state: SongState = initSong, action: SongResultInterface) : SongState {
    switch (action.type) {
        case "GET_SONG":
            return {
                ...state,
                data: {
                    id: action.song.id,
                    name: action.song.name,
                    slug: action.song.slug,
                    thumbnail_url: action.song.thumbnail_url,
                    artist: action.song.artist,
                    favorite: action.song.favorite,
                    song_time: action.song.song_time,
                    link_stream: action.song.link_stream,
                    link_download: action.song.link_download,
                    view_count: action.song.view_count,
                    relate_song: action.song.relate_song
                }
            }

        case "AUTO_PLAY":
            state.data.relate_song.splice(0, 1)
            return {
                ...state,
                data: {
                    id: action.autoplay.id,
                    name: action.autoplay.name,
                    slug: action.autoplay.slug,
                    thumbnail_url: action.autoplay.thumbnail_url,
                    artist: action.autoplay.artist,
                    favorite: action.autoplay.favorite,
                    song_time: 0,
                    link_stream: action.autoplay.link_stream,
                    link_download: action.autoplay.link_download,
                    // relate_song: state.data.relate_song.,
                    relate_song: state.data.relate_song,
                    view_count: action.autoplay.view_count,
                }
            }

        case "ADD_FAVORITE":
            return {
                ...state,
                data: {
                    id: state.data.id,
                    name: state.data.name,
                    slug: state.data.slug,
                    thumbnail_url: state.data.thumbnail_url,
                    artist: state.data.artist,
                    favorite: true,
                    link_stream: state.data.link_stream,
                    link_download: state.data.link_download,
                    relate_song: state.data.relate_song
                }
            }

        case "REMOVE_FAVORITE":
            return {
                ...state,
                data: {
                    id: state.data.id,
                    name: state.data.name,
                    slug: state.data.slug,
                    thumbnail_url: state.data.thumbnail_url,
                    artist: state.data.artist,
                    favorite: false,
                    link_stream: state.data.link_stream,
                    link_download: state.data.link_download,
                    relate_song: state.data.relate_song
                }
            }

        case "LOADMORE_RELATE":
            return {
                ...state,
                data: {
                    id: action.song.id,
                    name: action.song.name,
                    slug: action.song.slug,
                    thumbnail_url: action.song.thumbnail_url,
                    artist: action.song.artist,
                    favorite: action.song.favorite,
                    song_time: 0,
                    link_stream: action.song.link_stream,
                    link_download: action.song.link_download,
                    relate_song: state.data.relate_song
                }
            }

        case "GET_VIEWCOUNT":
            return {
                ...state,
                data: {
                    id: state.data.id,
                    name: state.data.name,
                    slug: state.data.slug,
                    thumbnail_url: state.data.thumbnail_url,
                    artist: state.data.artist,
                    favorite: state.data.favorite,
                    link_stream: state.data.link_stream,
                    link_download: state.data.link_download,
                    relate_song: state.data.relate_song,
                    view_count: action.count
                }
            }

        default:
            return state
    }
}

export default song