import * as React from "react"
import * as ReactDOM from "react-dom"
import { Provider } from "react-redux"
import App from "./container/App"
import store from './store'

// Assets
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "video-react/dist/video-react.css"
import "video.js/dist/video-js.css"
import "./asset/css/style.css"
// import './asset/css/style_tet.css'

// Global interface
declare global {
    interface Window {
        jwplayer: any
    }
}

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById("root")
);
