import * as React from "react"
import {CategoryState} from "../reducer/type/category";
import StateInterface from "../reducer/type";
import {bindActionCreators, Dispatch} from "redux";
import * as CategoryAction from "../action/category";
import {connect} from "react-redux";
import Menu from "../component/Menu";
import Header from "../component/Header";
import LoadingScreen from "../component/LoadingScreen";
import * as $ from "jquery";
import {HomeState, HomeVideoItemInterface} from "../reducer/type/home";
import {Link} from "react-router-dom";
import * as HomeAction from "../action/home";
import * as ls from "local-storage";
import * as AuthAction from "../action/auth";

interface StatePropsInterface {
    category: CategoryState,
    home: HomeState
}

interface DispatchPropsInterface {
    actions: {
        getCategoryAction: any,
        getRankAction: any,
        getInfoAction: any,
    }
}

interface ParamPropsInterface {
    match: {
        params: {
            id: string
        }
    },
    location: any,
    history: any
}

const mapStateToProps = (state: StateInterface) => ({
    category: state.category,
    home: state.home
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({
        getCategoryAction: CategoryAction.getCategoryAction,
        getRankAction: CategoryAction.getRankAction,
        getInfoAction: AuthAction.getInfoAction,
    }, dispatch)
});

type PropsInterface = StatePropsInterface & DispatchPropsInterface & ParamPropsInterface

interface CurrentStateInterface {
    loading: boolean,
    scroll: number
}

class Rank extends React.Component <PropsInterface, CurrentStateInterface> {

    constructor (props: PropsInterface) {
        super(props);
        this.props.actions.getCategoryAction();
        if (typeof ls.get<string>('token') == 'string') {
            this.props.actions.getInfoAction();
        }
        this.state = {
            loading: true,
            scroll: 0
        }
        this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {

        if (this.props.category.rank.length == 0) {
            this.props.actions.getRankAction();
        } else {
            this.setState({
                loading: false
            })
        }

        // Scroll
        window.addEventListener('scroll', this.trackScrolling, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.trackScrolling, false);
    }

    componentDidUpdate(prevProps) {
        if (this.props.category.rank !== prevProps.category.rank) {
            this.setState({
                loading: false
            })
        }
    }

    trackScrolling () {
        let s = $(window).scrollTop();

        // let dom = document.documentElement.scrollHeight;
        let dom = document.body.scrollHeight;
        let dom2 = window.innerHeight;
        // let dom2 = document.documentElement.clientHeight;

        let scrollPercent = (s / (dom - dom2)) * 100;
        this.setState({
            scroll: scrollPercent
        })
    }

    render() {

        let bxhContent = this.props.category.rank.map( (item: HomeVideoItemInterface, key) => {

            let rankIndex;
            if (key == 0) {
                rankIndex = (
                    <div className='clearfix'>
                        <img className="bxh-item-score" src={ require('../asset/images/bxh-01.png') }/>
                        <img className="bxh-item-score pl-2" src={ require('../asset/images/bxh-star.png') }/>
                    </div>
                );
            } else if (key == 1) {
                rankIndex = (
                    <div className='clearfix'>
                        <img className="bxh-item-score" src={ require('../asset/images/bxh-02.png') }/>
                    </div>
                );
            } else if (key == 2) {
                rankIndex = (
                    <div className='clearfix'>
                        <img className="bxh-item-score" src={ require('../asset/images/bxh-03.png') }/>
                    </div>
                );
            } else if (key == 3) {
                rankIndex = (
                    <div className='clearfix'>
                        <img className="bxh-item-score" src={ require('../asset/images/bxh-04.png') }/>
                    </div>
                );
            } else {
                let showKey: any = key + 1;
                if (showKey < 10) {
                    showKey = '0' + showKey.toString();
                }
                rankIndex = (
                    <div className='clearfix'>
                        <div className="bxh-item-score-text">{ showKey }</div>
                    </div>
                );
            }

            return (
                <div className='col-12 pr-2 pl-2' key={key}>
                    <Link to={'/bang-xep-hang/play/'+ item.id + '-' + item.slug} className='bxh-item'>
                        <div className='row'>
                            <div className='col-6 pr-0'>
                                <div className="position-relative">
                                    <img className="bxh-item-img" src={ item.thumbnail_url }/>
                                    <div className="content-item-time-right" style={{ bottom: "5px" }}>
                                        <div className="content-item-time">
                                            { item.time }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                {rankIndex}
                                <div className='bxh-divide'/>
                                <div className='bxh-item-name'>{ item.name }</div>
                                <div className='bxh-item-singer'>{ item.artist }</div>
                            </div>
                        </div>
                    </Link>
                </div>
            );
        });

        let loadingScreen;
        if (this.state.loading) {
            loadingScreen = (<LoadingScreen/>)
        }

        return (
            <div id="container" className="container-fluid overflow-hidden">
                <img src={ require('../asset/images/bg-bxh.png') } alt="bg" className="bg-rank"/>
                <div className="menu-overlay"/>
                <Menu/>
                <Header logo="Bảng xếp hạng"/>
                {loadingScreen}

                <div className="row rank-group">
                    <div className="col-12 rank-head">
                        <div className="rank-head-text">
                            Top hit
                        </div>
                        <div className="mt-2">
                            <div className="rank-text-pos float-left">1</div>
                            <div className="rank-text-pos float-right">{ this.props.category.rank.length }</div>
                            <div className="clearfix"/>
                        </div>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: this.state.scroll + '%'}}/>
                        </div>
                        <div className="clearfix"/>
                    </div>

                    <div className="col-12 pr-0 pl-0 rank-list">
                        {bxhContent}
                    </div>
                </div>
            </div>
        );
    }

}

export default connect<StatePropsInterface, DispatchPropsInterface>(
    mapStateToProps,
    mapDispatchToProps
)(Rank);
