import {
    ArtistResultInterface
} from "../action/type/artist";
import {
    ArtistState
} from "./type/artist";

export const initArtist = {
    lienkhuc: {
        list: [] as object[],
        links: [] as {
            next?: string
        }
    },
    data: {
        list: [] as object[],
        links: [] as {
            next?: string
        }
    },
    detail: {
        id: '',
        name: '',
        thumbnail_url: '',
        slug: '',
        name_ansii: '',
        list_song: {
            list: [] as object[],
            links: {} as {
                next?: string
            }
        },
        relate_artist: [] as object[]
    }
}

function artist (state: ArtistState = initArtist, action: ArtistResultInterface) : ArtistState {

    switch (action.type) {

        case "LOADMORE_LIENKHUC":
            return {
                ...state,
                lienkhuc: {
                    list: state.lienkhuc.list.concat(action.lienkhuc.data),
                    links: {
                        next: action.lienkhuc.meta.pagination.links.next
                    }
                }
            }

        case "LIST_LIENKHUC":
            return {
                ...state,
                lienkhuc: {
                    list: action.lienkhuc.data,
                    links: {
                        next: action.lienkhuc.meta.pagination.links.next
                    }
                }
            }

        case "GET_ARTIST":
            return {
                ...state,
                data: {
                    list: action.artist.data,
                    links: {
                        next: action.artist.meta.pagination.links.next
                    }
                }
            }

        case "LOADMORE_ARTIST":
            return {
                ...state,
                data: {
                    list: state.data.list.concat(action.loadmore.data),
                    links: {
                        next: action.loadmore.meta.pagination.links.next
                    }
                }
            }

        case "DETAIL_ARTIST":
            return {
                ...state,
                detail: {
                    id: action.detail.id,
                    name: action.detail.name,
                    thumbnail_url: action.detail.thumbnail_url,
                    slug: action.detail.slug,
                    name_ansii: action.detail.name,
                    list_song: {
                        list: action.detail.list_song.data,
                        links: {
                            next: action.detail.list_song.meta.pagination.links.next
                        }
                    },
                    relate_artist: action.detail.relate_artist
                }
            }

        case "LOADMORE_DETAIL":
            return {
                ...state,
                detail: {
                    id: action.detail.id,
                    name: action.detail.name,
                    thumbnail_url: action.detail.thumbnail_url,
                    slug: action.detail.slug,
                    name_ansii: action.detail.name,
                    list_song: {
                        list: state.detail.list_song.list.concat(action.detail.list_song.data),
                        links: {
                            next: action.detail.list_song.meta.pagination.links.next,
                        }
                    },
                    relate_artist: action.detail.relate_artist
                }
            }

        case "RESET_ARTIST":
            return {
                ...state,
                detail: {
                    id: state.detail.id,
                    name: state.detail.name,
                    thumbnail_url: state.detail.thumbnail_url,
                    slug: state.detail.slug,
                    name_ansii: state.detail.name,
                    list_song: {
                        list: [],
                        links: {
                            next: '',
                        }
                    },
                    relate_artist: state.detail.relate_artist
                }
            }

        default:
            return state
    }

}

export default artist
