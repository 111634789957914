import {
    AuthState
} from "./type/auth";
import {
    AuthResultInterface
} from "../action/type/auth";

const initAuth = {
    token: null,
    data: {
        phone: null,
        package: ''
    }
}

function auth(state: AuthState = initAuth, action: AuthResultInterface): AuthState {
    switch (action.type) {
        case "POST_LOGIN":
            return {
                ...state,
                token: action.token,
                data: action.data
            }

        case "LOGIN_FAIL":
            return {
                ...state,
                error: action.data
            }

        case "POST_LOGOUT":
            return {
                ...state,
                token: null,
                data: {
                    phone: null
                }
            }

        case "CHANGE_PASS":
            return {
                ...state,
                data: {
                    is_change_pass: '2'
                }
            }

        case "CHANGE_PASS_FAIL":
            return {
                ...state,
                error: action.data
            }

        case "GET_INFO":
            return {
                ...state,
                data: action.data
            }

        case "RESET_ERROR":
            return {
                ...state,
                error: undefined
            }

        case "AUTO_LOGIN":
            return {
                ...state,
                token: action.token,
                data: action.data
            }

        default:
            return state
    }
}

export default auth