import {
    CategoryResultInterface
} from "../action/type/category";
import {CategoryListItemInterface, CategoryState} from './type/category'

export const initCategory = {
    data: [] as object[],
    rank: [] as object[],
    detail: {
        id: '',
        name: '',
        list: [] as object[],
        links: [] as {
            next?: string
        }
    },
    favorite: {
        list: [] as object[],
        links: [] as {
            next?: string
        }
    }
}

function category (state: CategoryState = initCategory, action: CategoryResultInterface) : CategoryState {
    switch (action.type) {
        case "GET_CATEGORY":
            return {
                ...state,
                data: action.data
            };

        case "GET_RANK":
            return {
                ...state,
                rank: action.rank
            }

        case "DETAIL_CATEGORY":
            return {
                ...state,
                detail: {
                    id: action.detail.id,
                    name: action.detail.name,
                    list: action.detail.list.data,
                    links: {
                        next: action.detail.list.meta.pagination.links.next
                    }
                }
            };

        case "LOAD_MORE_CATEGORY":
            return {
                ...state,
                detail: {
                    id: state.detail.id,
                    name: state.detail.name,
                    list: state.detail.list.concat(action.loadmore.list.data),
                    links: {
                        next: action.loadmore.list.meta.pagination.links.next
                    }
                }
            }

        case "LIST_FAVORITE":
            return {
                ...state,
                favorite: {
                    list: action.favorite.data,
                    links: {
                        next: action.favorite.meta.pagination.links.next
                    }
                }
            }

        case "REMOVE_LIST_FAVORITE":
            let index = state.favorite.list.findIndex((item: CategoryListItemInterface) => {
                return item.id == action.song_id
            });
            state.favorite.list.splice(index, 1);
            return {
                ...state,
                favorite: {
                    list: state.favorite.list,
                    links: state.favorite.links
                }
            }

        case "LOADMORE_LIST_FAVORITE":
            return {
                ...state,
                favorite: {
                    list: state.favorite.list.concat(action.favorite.data),
                    links: action.favorite.meta.pagination.links
                }
            }

        case "RESET_CATEGORY":
            return {
                ...state,
                detail: {
                    id: state.detail.id,
                    name: state.detail.name,
                    list: [],
                    links: {
                        next: state.detail.links.next
                    }
                }
            }

        default:
            return state;
    }
}

export default category